import request from '@/http.js'

/**
 * @description 订单管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function orderListApi (param) {
    return request.post(
        '/admin.order.order/getList',
        param
    )
}
/**
 * @description 订单管理 -- 状态
 * @param {Object} param data {Object} 传值参数
 */
 export function orderStatusListApi (param) {
    return request.post(
        '/config/getOrderStatus',
        param
    )
}

/*****************************投诉订单*****************************/
/**
 * @description 投诉订单 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function complaintOrderListApi (param) {
    return request.post(
        '/admin.order.orderComplains/getList',
        param
    )
}
/**
 * @description 投诉订单 -- 详情
 * @param {Object} param data {Object} 传值参数
 */
 export function complaintOrderReadApi (param) {
    return request.post(
        '/admin.order.orderComplains/read',
        param
    )
}

/**
 * @description 回访记录 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function returnVisitListApi (param) {
    return request.post(
        '/admin.order.orderVisit/getList',
        param
    )
}
/**
 * @description 回访记录 -- 回访保存
 * @param {Object} param data {Object} 传值参数
 */
 export function returnVisitSavetApi (param) {
    return request.post(
        '/admin.order.orderVisit/edit',
        param
    )
}

/**
 * @description 回访记录 -- 回访保存
 * @param {Object} param data {Object} 传值参数
 */
 export function refundApi (param) {
    return request.post(
        '/admin.order.order/refund',
        param
    )
}

// 新增预售模块

 export function remindApi (param) {
    return request.post(
        '/admin.order/remind',
        param
    )
}

export function ysalesorderListApi (param) {
    return request.post(
        '/admin.order.order/getListPre',
        param
    )
}